import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Ellipse1, Ellipse2, WelcomeSplash1, WelcomeSplash2 } from "../../../../assets/images";

function SplashTwo() {
    const navigate = useNavigate();
    // useEffect(() => {
    //     new Toaster().success('jkhdfkhgfg')
    // }, [])
    return (
        <div className="welcome_screen">
            <Container>
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Row>
                            <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                                <div className="splash_bottom">
                                    <div className="bottom_area">
                                        <div className="details">
                                            <div className="img_one">
                                                <Image src={WelcomeSplash1} alt="..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                <div className="description">
                                    <div>
                                        <h3>Welcome !</h3>
                                        <p>Oxford Hospital is one of the best places to get medical treatment and consultation for heart problems and sugar problem. Our hospital has earned recognition as one of the best heart clinics in Jalandhar. Oxford Hospital is housing sincere and dedicated medical and para-medical staff to ensure best services to the patients.</p>
                                        <div className="img_area">
                                            <div className="img_1">
                                                <Image src={Ellipse1} alt="..." />
                                            </div>
                                            <div className="img_2">
                                                <Image src={Ellipse2} alt="..." />
                                            </div>
                                        </div>
                                        <div className="skip_btn">
                                            <a href="login">Skip</a>
                                        </div>
                                        <div className="btn_area_bottom">
                                            <Button type="button" onClick={() => navigate('/easy-use')}>Next <FontAwesomeIcon icon={faArrowRight} /></Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SplashTwo;