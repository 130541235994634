import WebConstant from "../../webConstants";
import { mainWrapper } from "../../main";


function medicineListing(params) {
    return mainWrapper.get(WebConstant.host + `/assign_medicine_list`, params);
};


function medicineCreate(params) {
    return mainWrapper.post(WebConstant.host + `/assign_medicine`, params);
};


function medicineDetail(id) {
    return mainWrapper.get(WebConstant.host + `/assign_medicine_detail/${id}`);
};


function medicineUpdate(id, params) {
    return mainWrapper.put(WebConstant.host + `/edit_medicine/${id}`, params);
};


function medicineDelete (id, ids) {
    return mainWrapper._delete(WebConstant.host + `/delete_medicine/${id}/${ids}`);
};


function brandNameSuggestionsList(params) {
    return mainWrapper.get(WebConstant.host + `/brand_name_suggestion_list`,params );
};


function medicineNameSuggestionsList(params) {
    return mainWrapper.get(WebConstant.host + `/medicine_name_suggestion_list`,params);
};


function completeMedicine(id, params) {
    return mainWrapper.put(WebConstant.host + `/update_medicine_status/${id}`, params);
};

function completeAllMedicines(params) {
    return mainWrapper.put(WebConstant.host + `/update_all_medicine_status`, params);
};


function addComment(id, params) {
    return mainWrapper.put(WebConstant.host + `/create_medicine_comment/${id}`, params);
};


export const medicineServices = {
    medicineListing,
    medicineCreate,
    medicineDelete,
    medicineUpdate,
    medicineDetail,
    brandNameSuggestionsList,
    medicineNameSuggestionsList,
    completeMedicine,
    completeAllMedicines,
    addComment
};
