import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Dropdown, Form, Image, Row } from "react-bootstrap";
//-------- HEADER COMPONENT IMPORT ---------//
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
//------------ CONSTANTS IMPORT ------------//
import { faAngleDown, faAngleUp, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import {
    Radiology
} from "../../../../assets/images";
import ControlledCarousel from "../../../Helper/ControlledCarousel";
import ButtonLoader from "../../../Helper/buttonLoader";
import * as Constants from "../../../Helper/constants/constants";
import SelectAssignTask from "../../Modals/SelectAssignTask";
import AddTaskFunctions from "./functions";
import Select from 'react-select';
import TaskAssignModal from "../../Modals/TaskAssignModal";
import TaskModal from "../../Modals/TaskModal";

const AssignTask = () => {
    const {
        breadcrumData,
        breadcrumItem,
        setbreadcrumItem,
        loaction,
        navigate,
        loader,
        buttonLoader,
        values,
        isError,
        handleChange,
        handleSubmit,
        handlePatientDataOnChange,
        getTaskDetail,
        getAdminsList,
        adminList,
        assignTaskModal,
        setAssignTaskModal,
        getPatientList,
        patientsList,
        getTaskPeriorities,
        taskPriorities,
        handleSelectAdmin,
        selectedAssigntedTo,
        handleModalSearch,
        clearAllSelectedAssignedTo,
        handleApplyAssignedTo,
        adminListSearch,
        patientCases,
        onWardClick,
        onPatientCaseClick,
        dropdownIcon,
        setDropdownIcon,
        dropdownIcon2,
        setDropdownIcon2,
        handleEndTime,
        handleStartTime,
        handleStartTimeDisable,
        handleEndTimeDisable,
        checkAssignedMembersValue,
        checkAdminsListCheckedValue,
        handleAsignMembersModalClose,
        handleBreadcrumDataNaviagtion,
        taskModal,
        setTaskModal,
        taskResponse,
        getPetientCaseListing,
        setValues,
        focus,
        setFocus,
        handleClick,
        inputCal
    } = AddTaskFunctions();

    useEffect(() => {
        if (loaction && loaction.state && loaction.state.name) {                                 //<------------------EDIT CASE
            setbreadcrumItem('Edit Task');
            getAdminsList();
            getPatientList();
            getTaskPeriorities();
            // getTaskDetail(id);
        }
        else if (loaction && loaction.state && loaction.state.patientData) {
            let patientData = loaction.state.patientData
            let patientDetail = {
                "patientId": patientData && patientData.id ? patientData.id : '',
                "patientName": patientData && patientData.patient_name ? patientData.patient_name : '',
                "hospitalId": patientData && patientData.cases[0] && patientData.cases[0].hospital_id ? patientData.cases[0].hospital_id : '',
                "caseId": patientData && patientData.cases[0] && patientData.cases[0].id ? patientData.cases[0].id : '',
                "caseTitle": patientData && patientData.cases[0] && patientData.cases[0].case_title
                    ? patientData.cases[0].case_title : '',
                "wardId": patientData && patientData.cases[0] && patientData.cases[0].ward_id ? patientData.cases[0].ward_id : '',
                "wardName": patientData && patientData.cases[0] && patientData.cases[0].ward_title ? patientData.cases[0].ward_title : '',
                "label": patientData && patientData.patient_name ? patientData.patient_name : '',
                "id": patientData && patientData.id ? patientData.id : '',
            }

            setValues({
                ...values,
                patientData: patientDetail,
            });
            getAdminsList();
            getPatientList();
            getTaskPeriorities();
        }
        else {
            getPatientList();
            getAdminsList();
            getTaskPeriorities();
        }
    }, []);

    useEffect(() => {
        if (focus) {
            setFocus(false)
        }
    }, [handleClick])


    return (
        <div className="assign_task_screen">
            <WithoutImageHeader
                header={loaction && loaction.state && loaction.state.name ? Constants.EDIT_TASK : Constants.ASSIGN_TASKS}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBack={() => navigate(-1)}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
            />
            <div className="parent_area">
                <div className="innerr_area">
                    <Container>
                        <Row>
                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div className="form">
                                    <Form>
                                        <Row>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group" onClick={() => setAssignTaskModal(true)}>
                                                    <Form.Label>Assign To</Form.Label>
                                                    <span>*</span>
                                                    {/* <Form.Control
                                                        type="text"
                                                        placeholder={values && values.assignTo && values.assignTo.length + " " + 'Selected'}
                                                        defaultValue=""
                                                        disabled={true}
                                                        className={isError.assignTo.message
                                                            ? "error_border"
                                                            : ""}
                                                        
                                                    /> */}
                                                    <div style={{
                                                        height: '60px',
                                                        fontSize: '22px',
                                                        fontWeight: '400',
                                                        paddingLeft: '20px',
                                                        border: isError.assignTo.message ? '1.5px solid red' : '1.5px solid #373780',
                                                        borderRadius: '10px',
                                                        color: '#7A7A7A',
                                                        backgroundColor: '#ffffff',
                                                        alignItems: 'center'
                                                    }}
                                                    >
                                                        <h5 style={{ paddingTop: '15px', color: '#7A7A7A', opacity: 0.5 }}>{values && values.assignTo && values.assignTo.length + " " + 'Selected'}</h5>
                                                    </div>
                                                    {isError.assignTo.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.assignTo.message}
                                                        </p>
                                                    ) : null}
                                                    {/* <a href="javascript:;">
                                                        <div className="icon" onClick={() => setAssignTask(true)}>
                                                            <FontAwesomeIcon icon={faSearch} />
                                                        </div>
                                                    </a> */}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                {
                                                    values && values.assignTo && values.assignTo.length > 0 ?
                                                        <ControlledCarousel
                                                            list={values && values.assignTo}
                                                        />
                                                        :
                                                        null
                                                }
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                {/* <Form.Group className="form-group">
                                                    <Form.Label>Patient Name</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        placeholder="Radiology"
                                                        defaultValue=""
                                                    />
                                                </Form.Group> */}
                                                <div className="dropdown_box">
                                                    <h6>Patient Name<span>*</span></h6>
                                                    <div className="dropdown_area" style={{ textTransform: 'capitalize' }}>
                                                        <Select
                                                            isDisabled={loaction && loaction.state && loaction.state.name ? true : false}
                                                            value={values && values.patientData}
                                                            options={patientsList}
                                                            onChange={(item) => (getPetientCaseListing(item && item.id), handlePatientDataOnChange(item))}
                                                            className={
                                                                isError.patientData.message
                                                                    ? "error_border_select"
                                                                    : ""
                                                            }
                                                        />
                                                        {isError.patientData.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError.patientData.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>MR Number</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        placeholder="MR Number"
                                                        defaultValue=""
                                                        value={values && values.patientData && values.patientData.caseId ? values.patientData.caseId : ''}
                                                        className={isError.patientData.message
                                                            ? "error_border"
                                                            : ""}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "patientData",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    {isError.patientData.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.patientData.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                                {/* <div className="dropdown_box">
                                                    <h6>Patient Case<span>*</span></h6>
                                                    <div className="dropdown_area" onClick={() => onPatientCaseClick()} >
                                                        <Select
                                                            value={values && values.caseData}
                                                            options={patientCases}
                                                            onChange={(item) => handleChange("caseData", item)}
                                                            isDisabled={(values.patientData === null || values.patientData === '') ? true : false}
                                                            className={
                                                                isError.caseData.message
                                                                    ? "error_border_select"
                                                                    : ""
                                                            }
                                                        />
                                                        {isError.caseData.message ? (
                                                            <p className="text-danger mt-2">
                                                                {isError.caseData.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div> */}
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group" onClick={() => onWardClick()}>
                                                    <Form.Label>Ward</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        disabled
                                                        type="text"
                                                        placeholder="Select Ward"
                                                        defaultValue=""
                                                        value={values && values.patientData && values.patientData.wardName ? values.patientData.wardName : ''}
                                                        className={isError.patientData.message
                                                            ? "error_border"
                                                            : ""}
                                                        onChange={(e) =>
                                                            handleChange(
                                                                "patientData",
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                    <a>
                                                        <div className="icon" >
                                                            {/* <FontAwesomeIcon icon={faRadio} /> */}
                                                            <Image src={Radiology} alt="..." />
                                                        </div>
                                                    </a>
                                                    {isError.patientData.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.patientData.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Task Title</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Enter task title here."
                                                        defaultValue=""
                                                        value={values.title}
                                                        className={isError.title.message
                                                            ? "error_border"
                                                            : ""}
                                                        onChange={(e) => handleChange("title", e.target.value)}
                                                    />
                                                    {isError.title.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.title.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <Form.Group className="form-group">
                                                    <Form.Label>Task Description</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={8}
                                                        placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eget semper tellus"
                                                        value={values.description}
                                                        className={isError.description.message
                                                            ? "error_border"
                                                            : ""}
                                                        onChange={(e) => handleChange("description", e.target.value)}
                                                    />
                                                    {isError.description.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.description.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group className="form-group" id="dob_box" >
                                                    <Form.Label>Task Priority</Form.Label>
                                                    <span>*</span>
                                                    <Form.Select
                                                        onChange={(e) => handleChange("priority", e.target.value)}
                                                        className={isError.priority.message
                                                            ? "error_border"
                                                            : ""}
                                                    >
                                                        <option disabled selected>{values.priority && values.priority.title ? values.priority.title : 'Select'}</option>
                                                        {
                                                            taskPriorities.map(list => (
                                                                <option
                                                                    key={list.id}
                                                                    value={list.id}
                                                                    label={list.title}
                                                                />
                                                            ))
                                                        }
                                                    </Form.Select>
                                                    {isError.priority.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.priority.message}
                                                        </p>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={12}>
                                                <Form.Group className="form-group" id="dob_box" >
                                                    <Form.Label>Date</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        //className="assign_date"
                                                        //readOnly={true}
                                                        onFocus={() => setFocus(true)}
                                                        disabled={focus}
                                                        ref={inputCal}
                                                        type="date"
                                                        min={moment().format("YYYY-MM-DD")}
                                                        placeholder="DD/MM/YYYY"
                                                        value={values.startDate}
                                                        className={isError.startDate.message
                                                            ? "error_border"
                                                            : "assign_date"}
                                                        onChange={(e) => handleChange("startDate", e.target.value)}
                                                    />
                                                    {isError.startDate.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.startDate.message}
                                                        </p>
                                                    ) : null}
                                                    {/* {<Datepicker />} */}
                                                    <a>
                                                        <div className="icon">
                                                            <FontAwesomeIcon icon={faCalendarDays}
                                                                onClick={() => handleClick()}
                                                            />
                                                        </div>
                                                    </a>
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form.Group className="form-group" id="dob_box" onClick={() => handleStartTimeDisable()}>
                                                    <Form.Label>Start time</Form.Label>
                                                    <span>*</span>
                                                    <Form.Control
                                                        disabled={values && values.startDate ? false : true}
                                                        type="time"
                                                        placeholder="Ex. 11:00 PM"
                                                        defaultValue={''}
                                                        value={values.startTime ? values.startTime : ''}
                                                        className={isError.startTime.message
                                                            ? "error_border"
                                                            : ""}
                                                        onChange={(e) => handleStartTime(e)}
                                                    />
                                                    {isError.startTime.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.startTime.message}
                                                        </p>
                                                    ) : null}
                                                    {/* <div className="clock_icon">
                                                        <Image src={Clock} alt="..." />
                                                    </div> */}
                                                </Form.Group>
                                            </Col>
                                            <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                                                <Form.Group className="form-group" id="dob_box" onClick={() => handleEndTimeDisable()}>
                                                    <Form.Label>End time</Form.Label>
                                                    {/* <span>*</span> */}
                                                    <Form.Control
                                                        disabled={values && values.startTime ? false : true}
                                                        type="time"
                                                        // min={moment().format("HH:mm")}
                                                        placeholder="Ex. 11:00 PM"
                                                        value={values.endTime ? values.endTime : ''}
                                                        className={isError.endTime.message
                                                            ? "error_border"
                                                            : ""}
                                                        onChange={(e) => handleEndTime(e)}
                                                    />
                                                    {isError.endTime.message ? (
                                                        <p className="text-danger mt-2">
                                                            {isError.endTime.message}
                                                        </p>
                                                    ) : null}
                                                    {/* <div className="clock_icon">
                                                        <Image src={Clock} alt="..." />
                                                    </div> */}
                                                </Form.Group>
                                            </Col>
                                            <div className="btn_area">
                                                <Button
                                                    className="cancelBtn"
                                                    type="button"
                                                    onClick={() => navigate(-1)}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className="submitBtn"
                                                    type="submit"
                                                    disabled={buttonLoader ? true : false}
                                                    onClick={handleSubmit}
                                                >
                                                    {buttonLoader ? (
                                                        <ButtonLoader
                                                            loader={buttonLoader}
                                                            variant={"light"}
                                                        />
                                                    ) : null}
                                                    {loaction && loaction.state && loaction.state.name ? "Edit Task" : "Assign"}
                                                </Button>
                                            </div>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {
                assignTaskModal ? (
                    <SelectAssignTask
                        show={assignTaskModal}
                        assignToValue={values && values.assignTo}
                        adminList={adminList}
                        selectedAssigntedTo={selectedAssigntedTo}
                        handleSelectAdmin={handleSelectAdmin}
                        handleModalSearch={handleModalSearch}
                        clearAllSelectedAssignedTo={clearAllSelectedAssignedTo}
                        handleApplyAssignedTo={handleApplyAssignedTo}
                        checkAssignedMembersValue={checkAssignedMembersValue}
                        checkAdminsListCheckedValue={checkAdminsListCheckedValue}
                        adminListSearch={adminListSearch}
                        close={() => handleAsignMembersModalClose()}
                    />
                ) : null
            }
            {
                taskModal ? <TaskModal
                    show={taskModal}
                    onClose={() => setTaskModal(false)}
                    taskResponse={taskResponse}
                /> : null
            }
        </div >
    );
};

export default AssignTask;
