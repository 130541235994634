import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Ellipse1, WelcomeSplash2, WelcomeSplash3 } from "../../../../assets/images";

function SplashThree() {
    const navigate = useNavigate();
    return (
        <div className="welcome_screen">
            <Container>
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Row>
                                        <Col xxl={5} xl={5} lg={5} md={5} sm={12} xs={12}>
                                            <div className="splash_bottom">
                                                <div className="bottom_area">
                                                    <div className="details">
                                                        <div className="img_one">
                                                            <Image src={WelcomeSplash3} alt="..." />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                                            <div className="description">
                                                <div>
                                                <h3>Easy to Use</h3>
                                                <p>With this App, you can easily manage all the data of your entire organization in here.</p>
                                                    <div className="img_area">
                                                        <div className="img_1">
                                                            <Image src={Ellipse1} alt="..." />
                                                        </div>
                                                        <div className="img_2">
                                                            <Image src={Ellipse1} alt="..." />
                                                        </div>
                                                    </div>
                                                    <div className="btn_area_bottom">
                                <Button type="button" className="btn btn-primary" onClick={() => navigate('/login')}>Get Started <FontAwesomeIcon icon={faArrowRight} /></Button>
                            </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
            
                                </Col>
                            </Row>
                        </Container>

            

        </div>
    );
}

export default SplashThree;