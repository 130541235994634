//------------ REACT IMPORT --------------//
import React, { useState } from "react";
//--------- BOOTSTRAP ITEMS IMPORT ----------//
import { Button, Card, Image } from "react-bootstrap";
//------------ IMAGES IMPORT ---------------//
import { Delete, Eye, ImgExt, PdfImg, editing } from "../../../../assets/images";
import AddReportModal from "../../Modals/AddReportModal";
import PaginationComponent from "../../CommanComponents/Pagination";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import Loader from "../../../Helper/loader";
import { dateFormat, isEmpty, renderImage, timeFormat } from "../../../Helper/General";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../Modals/DeleteModal";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import EditReportModal from "../../Modals/EditReportModal";


const Reports = (props) => {
    const [showReportModal, setShowReportModal] = useState(false);
    const [editReports, setEditReports] = useState(false);
    const [reportsdata, setReportsdata] = useState(null);
    const [deleteReport, setDeleteReport] = useState(null);
    const {
        loader,
        patientReportsList,
        reportListTotalPages,
        handleDeleteReport,
        handleReportsBackClick,
        handleReportsNextClick,
        handlePage,
        page,
        checkReportPermissions,
        handleRenderImage,
        patientData,
        User,
        deleteReportsDetail,
        setDeleteReportsDetail
    } = props

    let navigate = useNavigate();

    const handleDeletClick = (id) => {
        if (props && props.handleDeleteReport) {
            props.handleDeleteReport(deleteReport);
        }
        else return;
    };


    const handleAddReport = (values) => {
        if (props && props.handleaddReport) {
            props.handleaddReport(values);
            setShowReportModal(false);
        }
        else return;
    };

    const handleEditReport = (values) => {
        if (props && props.handleEditReport) {
            props.handleEditReport(values, reportsdata);
            setEditReports(false);
        }
        else return;
    };


    return (
        <>
            <div className="task_tab_screen">
                {
                    loader ?
                        <Loader
                            loader={loader}
                        />
                        :
                        patientReportsList && patientReportsList.length > 0 ?
                            <Card>
                                <Card.Body>
                                    {
                                        patientReportsList.map((item, i) => (
                                            <Card key={i}>
                                                <Card.Body>
                                                    <div className="card_details">
                                                        <div className="left_area">
                                                            <div className="inner_left_area">
                                                                <div className="img_one">
                                                                    {handleRenderImage(item)}
                                                                </div>
                                                            </div>
                                                            <div className="inner_right_area">
                                                                <div className="text_area">
                                                                    <h5>{item.title ? item.title : ''}</h5>
                                                                    <p>{item.description ? item.description : ''}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="right_area">
                                                            <div className="icons">
                                                                {!isEmpty(item.attachment) ? <Image
                                                                    src={Eye}
                                                                    alt="..."
                                                                    onClick={() => navigate('/Viewfile', { state: item.attachment })}
                                                                /> : null}
                                                                {User && parseInt(User.user_type) === 1 && patientData && patientData.patient_status !== 'discharge' ?
                                                                    <Image
                                                                        src={editing}
                                                                        alt="..."
                                                                        onClick={() => { setEditReports(true); setReportsdata(item) }}
                                                                    />
                                                                    :
                                                                    checkReportPermissions
                                                                        && checkReportPermissions.permissions
                                                                        && checkReportPermissions.permissions.length > 2
                                                                        && parseInt(checkReportPermissions.permissions[2].value) === 1 && patientData && patientData.patient_status !== 'discharge' ?
                                                                        <Image
                                                                            src={editing}
                                                                            alt="..."
                                                                            onClick={() => { setEditReports(true); setReportsdata(item) }}
                                                                        />
                                                                        :
                                                                        null

                                                                }
                                                                {User && parseInt(User.user_type) === 1 && patientData && patientData.patient_status !== 'discharge' ?
                                                                    <Image
                                                                        src={Delete}
                                                                        alt="..."
                                                                        onClick={() => { setDeleteReportsDetail(true); setDeleteReport(item.id) }}
                                                                    />
                                                                    :
                                                                    checkReportPermissions
                                                                        && checkReportPermissions.permissions
                                                                        && checkReportPermissions.permissions.length > 3
                                                                        && parseInt(checkReportPermissions.permissions[3].value) === 1 && patientData && patientData.patient_status !== 'discharge' ?
                                                                        <Image
                                                                            src={Delete}
                                                                            alt="..."
                                                                            onClick={() => { setDeleteReportsDetail(true); setDeleteReport(item.id) }}
                                                                        />
                                                                        :
                                                                        null

                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="report_details">
                                                        <div className="report_updated_by">
                                                            {
                                                                item.created_by ?
                                                                    <>
                                                                        <p style={{ cursor: 'pointer' }}
                                                                            onClick={() => navigate(`/staff-detail/${item.created_by}`, { state: { name: item.created_by_name, fromNavigation: true } })}
                                                                        >Updated by: {item.created_by_name}</p>
                                                                        <p>{dateFormat(item.created)}{' '}{timeFormat(item.created)}</p>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>

                                        ))
                                    }
                                </Card.Body>
                                {
                                    User && parseInt(User.user_type) === 1
                                        && patientData && patientData.patient_status !== 'discharge' ?
                                        <Button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => setShowReportModal(true)}
                                        >
                                            Add Reports
                                        </Button>
                                        :
                                        checkReportPermissions
                                            && checkReportPermissions.permissions
                                            && checkReportPermissions.permissions.length > 1
                                            && parseInt(checkReportPermissions.permissions[1].value) === 1
                                            && patientData && patientData.patient_status !== 'discharge' ?
                                            <Button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => setShowReportModal(true)}
                                            >
                                                Add Reports
                                            </Button>
                                            :
                                            null
                                }
                            </Card>
                            :
                            User && parseInt(User.user_type) === 1
                                && patientData && patientData.patient_status !== 'discharge' ?
                                <Button
                                    className="btn btn-primary center_btn"
                                    type="button"
                                    onClick={() => setShowReportModal(true)}
                                >
                                    Add Reports
                                </Button>
                                :
                                checkReportPermissions
                                    && checkReportPermissions.permissions
                                    && checkReportPermissions.permissions.length > 1
                                    && parseInt(checkReportPermissions.permissions[1].value) === 1
                                    && patientData && patientData.patient_status !== 'discharge' ?
                                    <Button
                                        className="btn btn-primary center_btn"
                                        type="button"
                                        onClick={() => setShowReportModal(true)}
                                    >
                                        Add Reports
                                    </Button>
                                    :
                                    <NoDataComponent
                                        message="No Report Found!"
                                    />
                }
                {
                    showReportModal ? (
                        <AddReportModal
                            show={showReportModal}
                            close={() => setShowReportModal(false)}
                            handleAddReport={handleAddReport}
                        />
                    ) : null
                }
                {
                    editReports ? (
                        <EditReportModal
                            show={editReports}
                            close={() => setEditReports(false)}
                            handleAddReport={handleEditReport}
                            reportsdata={reportsdata}
                        />
                    ) : null
                }
            </div>
            {patientReportsList && patientReportsList.length > 0 ? (
                <PaginationComponent
                    page={page}
                    setPage={handlePage}
                    totalPages={reportListTotalPages}
                    handleBackClick={handleReportsBackClick}
                    handleNextClick={handleReportsNextClick}
                />
            ) : null}
            {
                deleteReportsDetail ?
                    <DeleteModal
                        show={deleteReportsDetail}
                        handleSubmit={() => {
                            if (props && props.handleDeleteReport) {
                                props.handleDeleteReport(deleteReport);
                            }
                        }}
                        close={() => setDeleteReportsDetail(false)}
                        message={'Do you want to delete this Report ?'}
                        icon={null}
                    />
                    :
                    null
            }

        </>
    )
}

export default Reports;