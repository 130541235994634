//------------- STYLES IMPORTS ------------//
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
//------------- ROUTER IMPORT ------------//
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";

import { useEffect, useRef, useState } from "react";

//---------- COMPONENT IMPORTS ---------//
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import DrawerComponent from "./components/Helper/drawerComponent";
import { LayoutOne, NoHeaderFooter } from "./components/Layout";
import ChangePassword from "./components/Screens/Auth/changePassword";
import EmailVerification from "./components/Screens/Auth/emailVerification";
import ForgetPassword from "./components/Screens/Auth/forgotPassword";
import Login from "./components/Screens/Auth/login";
import ResetPassword from "./components/Screens/Auth/resetPassword";
import AssignMedicine from "./components/Screens/CommanScreens/AssignMedicine";
import AssignTask from "./components/Screens/CommanScreens/AssignTask";
import Dashboard from "./components/Screens/CommanScreens/Dashboard";
import UpdateMedicine from "./components/Screens/CommanScreens/EditMedicine";
import MyTaskDetails from "./components/Screens/CommanScreens/MyTaskDetails";
import MyTasks from "./components/Screens/CommanScreens/MyTasks";
import Rooms from "./components/Screens/CommanScreens/Rooms";
import SearchScreen from "./components/Screens/CommanScreens/SearchScreens";
import Viewfile from "./components/Screens/CommanScreens/ViewFile";
import ReportDetailLab from "./components/Screens/LabsDesign/LabInfo/ReportDetail";
import AddReportBioOne from "./components/Screens/LabsDesign/LabReports/BioChemistyReport/AddReportOne";
import EditProfile from "./components/Screens/Nurses/EditProfile";
import MyProfile from "./components/Screens/Nurses/MyProfile";
import PatientDetails from "./components/Screens/Patient/PatientDetails";
import PatientList from "./components/Screens/Patient/PatientList";
import PatientProfile from "./components/Screens/Patient/PatientProfile";
import AddBed from "./components/Screens/SuperAdmin/BedManage/AddBed";
import BedList from "./components/Screens/SuperAdmin/BedManage/BedListing";
import DashboardSuperAdmin from "./components/Screens/SuperAdmin/Dashboard/Dashboard";
import ExistingPatient from "./components/Screens/SuperAdmin/Dashboard/ExistingPatientList";
import TodayPatientList from "./components/Screens/SuperAdmin/Dashboard/TodayPatientList";
import AddDepertment from "./components/Screens/SuperAdmin/DepartmentManage/AddDepartment";
import Department from "./components/Screens/SuperAdmin/DepartmentManage/DepartmentList";
import AddHospital from "./components/Screens/SuperAdmin/HospitalManage/AddHospital";
import HospitalDetails from "./components/Screens/SuperAdmin/HospitalManage/HospitalDetail";
import HospitalListingData from "./components/Screens/SuperAdmin/HospitalManage/HospitalListing_Data";
import AddCase from "./components/Screens/SuperAdmin/PatientManage/AddCase";
import AddPatient from "./components/Screens/SuperAdmin/PatientManage/AddPatient";
import EditPatient from "./components/Screens/SuperAdmin/PatientManage/EditPatient";
import ManagePatientList from "./components/Screens/SuperAdmin/PatientManage/ManagePatientList";
import AddRole from "./components/Screens/SuperAdmin/RoleManage/AddRole";
import RoleDetail from "./components/Screens/SuperAdmin/RoleManage/RoleDetail";
import RoleList from "./components/Screens/SuperAdmin/RoleManage/RoleList";
import AddRoom from "./components/Screens/SuperAdmin/RoomManage/AddRoom";
import RoomWithBed from "./components/Screens/SuperAdmin/RoomManage/AddRoomWithBed";
import RoomList from "./components/Screens/SuperAdmin/RoomManage/RoomListing";
import AddStaff from "./components/Screens/SuperAdmin/StaffManage/AddStaff";
import StaffDetail from "./components/Screens/SuperAdmin/StaffManage/StaffDetail";
import StaffList from "./components/Screens/SuperAdmin/StaffManage/StaffList";
import TaskList from "./components/Screens/SuperAdmin/TaskManage/TaskList";
import EditSuperAdminProfile from "./components/Screens/SuperAdmin/UpdateProfile";
import AddWard from "./components/Screens/SuperAdmin/WardManage/AddWArd";
import Wards from "./components/Screens/SuperAdmin/WardManage/Wards";
import SplashOne from "./components/Screens/intro/splashOne";
import SplashThree from "./components/Screens/intro/splashThree";
import SplashTwo from "./components/Screens/intro/splashTwo";
import store from "./redux/store";

function App() {
    const location = useLocation();
    const hiddenRoutes = ["/dashboard-superadmin", "/existing-patient",
        "/hospital", "/add-staff", "/hospital-listing", "/staff-list",
        "/manage-patient-list", "/task-list", "/assignTask", "/add-patient",
        "/search", "/change-password", "/role-list", "/role", "/wards",
        "/add-Ward", "/department", "/add-departement", "/bed-list", "/Add-Bed",
        "/room-list", "/add-Room", "/view-role", "/edit-Profile", "/add-case",
        "/assign-medicine", "/today-patient-List", "/edit-patient", "/myTaskDetails",
        "/report-detail-lab", "/edit-medicine", "/Viewfile","/dashboard", "/myTasks"];


    const dynamicRoutes = [
        /^\/hospital-detail\/\d+$/, // Matches /hospital-detail/{any_number}
        /^\/staff-detail\/\d+$/,
        /^\/patientDetails\/\d+$/,
    ];
    const windowHeight = useRef(window.innerHeight);
    const [toasterWidth, setToasterWidth] = useState(null);
    const [hideStyle, setHideStyle] = useState(false);

    useEffect(() => {
        setToasterWidth(window.innerWidth);
    }, []);

    const isHiddenRoute = (path) => {
        if (hiddenRoutes.includes(path)) return true;
        return dynamicRoutes.some((regex) => regex.test(path));
    };

    useEffect(() => {
        setHideStyle(isHiddenRoute(location.pathname));
    }, [location.pathname]);

    return (
        <div>
            <style>
                {`.hospital_wrapper {
            min-height: ${windowHeight.current}px
            }`}
            </style>
            <div>
            {/* <div className={`${!hideStyle ? "hospital_wrapper" : ""}`}> */}
                <Provider store={store}>
                    {/* <Router> */}
                    <Routes>
                        <Route element={<NoHeaderFooter />}>
                            <Route path="/" element={<SplashOne />} />
                            <Route path="/welcome" element={<SplashTwo />} />
                            <Route path="/easy-use" element={<SplashThree />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/forgot-Password" element={<ForgetPassword />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/email-verification" element={<EmailVerification />} />
                            <Route path="/change-password" element={<ChangePassword />} />
                            <Route path="/patient-list" element={<PatientList />} />
                            <Route path="/patient-profile" element={<PatientProfile />} />
                        </Route>
                        <Route element={<LayoutOne />}>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/rooms" element={<Rooms />} />
                            <Route path="/myprofile" element={<MyProfile />} />
                            <Route path="/editprofile" element={<EditProfile />} />
                            {/* <Route path="/patientDetails" element={<PatientDetails />} /> */}
                            <Route path="/patientDetails/:slug" element={<PatientDetails />} />
                            <Route path="/myTasks" element={<MyTasks />} />
                            <Route path="/myTaskDetails" element={<MyTaskDetails />} />
                            <Route path="/assignTask" element={<AssignTask />} />
                            <Route path="/hospital" element={<AddHospital />} />
                            {/* <Route path="/hospital-detail" element={<HospitalDetails />} /> */}
                            <Route path="/hospital-detail/:slug" element={<HospitalDetails />} />
                            {/* <Route path="/hospital-list" element={<HospitalList />} /> */}
                            <Route path="/role-list" element={<RoleList />} />
                            <Route path="/role" element={<AddRole />} />
                            <Route path="/view-role" element={<RoleDetail />} />
                            <Route path="/hospital-listing" element={<HospitalListingData />} />
                            <Route path="/add-patient" element={<AddPatient />} />
                            <Route path="/task-list" element={<TaskList />} />
                            <Route path="/staff-list" element={<StaffList />} />
                            <Route path="/add-staff" element={<AddStaff />} />
                            {/* <Route path="/staff-detail" element={<StaffDetail />} /> */}
                            <Route path="/staff-detail/:slug" element={<StaffDetail />} />
                            <Route path="/manage-patient-list" element={<ManagePatientList />} />
                            <Route path="/dashboard-superadmin" element={<DashboardSuperAdmin />} />
                            <Route path="/search" element={<SearchScreen />} />
                            <Route path="/edit-patient" element={<EditPatient />} />
                            <Route path="/add-case" element={<AddCase />} />
                            <Route path="/wards" element={<Wards />} />
                            <Route path="/add-Ward" element={<AddWard />} />
                            <Route path="/department" element={<Department />} />
                            <Route path="/add-departement" element={<AddDepertment />} />
                            <Route path="/today-patient-List" element={<TodayPatientList />} />
                            <Route path="/Viewfile" element={<Viewfile />} />
                            <Route path="/existing-patient" element={<ExistingPatient />} />
                            <Route path="/assign-medicine" element={<AssignMedicine />} />
                            <Route path="/edit-medicine" element={<UpdateMedicine />} />
                            <Route path="/add-room-With-Bed" element={<RoomWithBed />} />
                            <Route path="/add-Room" element={<AddRoom />} />
                            <Route path="/room-list" element={<RoomList />} />
                            <Route path="/bed-list" element={<BedList />} />
                            <Route path="/Add-Bed" element={<AddBed />} />
                            <Route path="/edit-Profile" element={<EditSuperAdminProfile />} />
                            {/* Lab-Design */}
                            <Route path="/add-lab-report" element={<AddReportBioOne />} />
                            <Route path="/report-detail-lab" element={<ReportDetailLab />} />
                        </Route>
                    </Routes>
                    <DrawerComponent />
                    {/* </Router> */}
                    <ToastContainer
                        position="top-center"
                        hideProgressBar={false}
                        autoClose={false}
                        newestOnTop={true}
                        closeOnClick={false}
                        draggable={false}
                        rtl={false}
                    />
                </Provider>
            </div>
        </div>
    );
}

export default App;
