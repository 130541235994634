import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster } from "../../../../Helper/toaster/Toaster";
import store from "../../../../../redux/store";
import adminController from "../../../../../apis/Controllers/AdminControler/admin.controller";
import { filtersController } from "../../../../../apis/Controllers/CommonController/filter.controller";
import { isEmptyArray } from "../../../../Helper/General";
import permissionsController from "../../../../../apis/Controllers/PermissionsController/permissions.contoller";
import { setDrawer } from "../../../../../redux/actions/users";



const StaffListFunctions = () => {
    var breadcrumData = ["Dashboard"];
    var breadcrumItem = "Staff list";
    var breadcrumData1 = ["Dashboard", "Staff List"];

    let storedFilters = store.getState().StaffListFilters.filters;
    const [checkPermissions, setCheckPermissions] = useState(null);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [stafList, setStafList] = useState([]);
    const [superAdminsList, setSuperAdminsList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [deletePopup, setDeletePopup] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [staffFilter, setStaffFilter] = useState(false);
    const [stafListLength, setStafListLength] = useState(null);
    const [superAdminsListLength, setSuperAdminsListLength] = useState(null);
    const [filtersAppliedSign, setFiltersAppliedSign] = useState(false);
    const [checkStoredvalueFromRedux, SetCheckStoredValueFromRedux] = useState(null);
    const [keyword, setSearch] = useState('');
    const [keyword2, setSearch2] = useState('');
    const [superAdminListFilters, setSuperAdminListFilters] = useState(false);
    const [sortingValue, setSortingValue] = useState(null);
    // -------------------PAZINATION Sub Admin----------------------
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [totalPages, setTotalPages] = useState([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    // --------------------------------------------------------------
    const [page2, setPage2] = useState(1);
    const [count2, setCount2] = useState(0);
    const [totalPages2, setTotalPages2] = useState([]);
    const [itemsPerPage2, setItemsPerPage2] = useState(10);
    //<--------------------------------------------------------------
    const [showMultiHosPopup, setShowMultiHosPopup] = useState(false);
    let navigate = useNavigate();
    const [allFiltersValues, setAllFiltersValues] = useState(false);

    const [currentTab, setCurrentTab] = useState('1');
    let userdata = localStorage.getItem('SET_USER_DATA');
    let User = JSON.parse(userdata);
    const [tabs, setTabs] = useState([
        {
            id: 1,
            tabTitle: "Staff",
        },
    ]);


    useEffect(() => {
        let aarry = [...tabs]
        let userdata = localStorage.getItem('SET_USER_DATA');
        let User = JSON.parse(userdata);
        if (User && parseInt(User.user_type) === 1) {
            aarry.push({
                id: 2,
                tabTitle: "Super Admin",
            })
            setTabs(aarry);
        }
        else;
    }, []);


    const getModulePermissions = async () => {
        let response = await permissionsController.permissionsDetails('staff');
        if (response && response.status) {
            let data = response && response.data && response.data.length > 0 ? response.data[0] : ''
            setCheckPermissions(data);
        }
        else {
            setCheckPermissions(null);
        }
        setLoader(false);
    };


    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux, allFiltersValues) {
            if (
                (storedFilters &&
                    storedFilters.department &&
                    storedFilters.department.length > 0) ||
                (storedFilters &&
                    storedFilters.hospitals &&
                    storedFilters.hospitals.length > 0) ||
                (storedFilters &&
                    storedFilters.role &&
                    storedFilters.role.length > 0) ||
                (storedFilters && storedFilters.adminStatus)
            ) {
                setFiltersAppliedSign(true);
                getStafListing("", page, itemsPerPage);
            } else {
                setFiltersAppliedSign(false);
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                getStafListing("", page, itemsPerPage, '', item);
            }
        }
    }, [storedFilters, checkStoredvalueFromRedux, allFiltersValues]);


    useEffect(() => {
        if (storedFilters && checkStoredvalueFromRedux) {
            if ((storedFilters &&
                storedFilters.hospitals &&
                storedFilters.hospitals.length > 0)
            ) {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                handleDashboardHospitalwithFiltersAppliedCase(item, storedFilters.hospitals);
            } else {
                let data = localStorage.getItem('SET_HOSPITAL');
                let item = JSON.parse(data);
                if (item) {
                    handleDashboardHospitalwithFilters(item);
                }
            }
        }
    }, [checkStoredvalueFromRedux]);


    const handleDashboardHospitalwithFiltersAppliedCase = async (seletedHospitalData, AppliedFilterHospitals) => {
        let applied = AppliedFilterHospitals
        if (applied.length > 1) {
            let index = applied.findIndex((id) => parseInt(id) === parseInt(seletedHospitalData.id))
            if (index === -1) {
                applied.push(seletedHospitalData.id);
            }
            // else {
            //     return applied
            // }
        }
        else {
            applied = []
            applied.push(seletedHospitalData.id);
        }
        let filterNew = {
            department: storedFilters && storedFilters.department ? storedFilters.department : [],
            role: storedFilters && storedFilters.role ? storedFilters.role : [],
            hospitals: applied,
            adminStatus: storedFilters && storedFilters.adminStatus ? storedFilters.adminStatus : null,
        };
        await filtersController.setStaffListFilters(filterNew);
        await setAllFiltersValues(true);
    };


    const handleDashboardHospitalwithFilters = async (seletedHospitalData) => {
        let filterNew = {
            department: storedFilters && storedFilters.department ? storedFilters.department : [],
            role: storedFilters && storedFilters.role ? storedFilters.role : [],
            hospitals: seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
            adminStatus: storedFilters && storedFilters.adminStatus ? storedFilters.adminStatus : null,
        };
        await filtersController.setStaffListFilters(filterNew);
        await setAllFiltersValues(true);
    };


    useEffect(() => {
        if (sortingValue) {
            getSuperAdminsListing('', page, itemsPerPage);
        }
    }, [sortingValue]);


    const handleTabData = (tabId) => {                    //<-------------onChange Tab
        if (parseInt(tabId) === 1) {
            getStafListing("", page, itemsPerPage);
        } else if (parseInt(tabId) === 2) {
            getSuperAdminsListing("", page2, itemsPerPage2);
        } else return;
    };


    useEffect(() => {
        if (count) {
            handlePages(count);
        }
    }, [count, itemsPerPage]);


    const handlePages = (count) => {
        let array = []
        const data = parseInt(count);
        const items = parseInt(itemsPerPage);
        const totalPage = Math.ceil(data / items);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i)
        }
        setTotalPages(array);
    };


    const getStafListing = async (e, page, itemPerPage, filters, seletedHospitalData) => {
        if (!loader) {
            let post = {
                search: e ? e : keyword ? keyword : "",
                page: page ? page : 1,
                limit: itemPerPage ? itemPerPage : 10,
                hospital_id: filters && filters.hospitals && filters.hospitals.length > 0 ? filters.hospitals : storedFilters && storedFilters.hospitals && storedFilters.hospitals.length > 0 ? storedFilters.hospitals : seletedHospitalData && seletedHospitalData.id ? [seletedHospitalData.id] : [],
                department_id: filters && filters.department ? filters.department : storedFilters && storedFilters.department ? storedFilters.department : [],
                role_id: filters && filters.role ? filters.role : storedFilters && storedFilters.role ? storedFilters.role : [],
                admin_status: filters && filters.adminStatus ? filters.adminStatus : storedFilters && storedFilters.adminStatus ? storedFilters.adminStatus : '',
            };
            setLoader(true);
            let response = await adminController.adminListing(post);
            if (response && response.status) {
                setStafList(response.data);
                setCount(response.count);
                setStafListLength(response.totalCount);
                setLoader(false);
            } else {
                setStafList([]);
                setCount(null);
                setStafListLength(null);
                setLoader(false);
            }
        } else {
            setLoader(false);
        }
        setLoader(false);
    };


    const updateStafStatus = async (item) => {
        let post = {
            status: item.status == 1 ? 0 : 1,
        };
        let response = await adminController.updateAdminStatus(item.id, post);
        if (response && response.status) {
            new Toaster().success(response.message);
            setLoader(false)
            if (parseInt(currentTab) === 1) {
                let array = [...stafList];
                item.status = parseInt(item.status) == 0 ? 1 : 0;
                setStafList(array)
            }
            else {
                let array = [...superAdminsList];
                item.status = parseInt(item.status) == 0 ? 1 : 0;
                setSuperAdminsList(array)
            }
        }
        else return;
    };


    const handleDeleteStaf = async () => {
        let array = [...stafList];
        let id = deleteItem && deleteItem.id ? deleteItem.id : "";
        setLoader(true);
        let response = await adminController.adminDelete(id);
        if (response && response.status) {
            setLoader(false);
            new Toaster().success(response.message);
            setDeletePopup(false);
            let filterData = array.filter((data) => data.id !== deleteItem.id);
            if (filterData.length > 0) {
                if (parseInt(currentTab) === 1) {
                    getStafListing("", page, itemsPerPage);
                }
                else {
                    getSuperAdminsListing("", page2, itemsPerPage2);
                }
            } else {
                if (parseInt(currentTab) === 1) {
                    if (page > 1) {
                        let newpage = page - 1;
                        setPage(page - 1);
                        getStafListing("", newpage, itemsPerPage);
                    } else {
                        setPage(page);
                        getStafListing("", page, itemsPerPage);
                    }
                }
                else {
                    if (page2 > 1) {
                        let newpage = page2 - 1;
                        setPage2(page2 - 1);
                        getSuperAdminsListing("", newpage, itemsPerPage2);
                    } else {
                        setPage2(page2);
                        getSuperAdminsListing("", page2, itemsPerPage2);
                    }
                }
            }
            setTotalPages([]);
        } else {
            setDeletePopup(false);
            setLoader(false);
        }
        setLoader(false);
    };


    const handleNextClick = () => {                              //<------------PAZINATION
        if (page >= 1 && page < totalPages.length) {
            let newPage = page + 1;
            setPage(page + 1);
            if (parseInt(currentTab) === 1) {
                getStafListing("", newPage, itemsPerPage);
            }
            else {
                getSuperAdminsListing("", page, itemsPerPage);
            }
        }
        else return;
    };


    const handleBackClick = () => {                             //<------------PAZINATION
        if (page > 1) {
            let newPage = page - 1;
            setPage(page - 1);
            getStafListing("", newPage, itemsPerPage);
        }
        else return;
    };


    const handleItemPerPage = (e) => {
        let value = e.target.value
        if (parseInt(currentTab) === 1) {
            getStafListing('', 1, value);
            setItemsPerPage(value);
        }
        else {
            getSuperAdminsListing("", 1, value);
            setItemsPerPage2(value);
        }
    };


    const handlePage = (e) => {                                //<-------------------- onChange Page
        if (parseInt(currentTab) === 1) {
            setPage(e);
            getStafListing("", e, itemsPerPage);
        }
        else {
            setPage2(e);
            getSuperAdminsListing("", e, itemsPerPage2);
        }
    };


    //<-------------------------------------------SUPER ADMIN LIST ----------------------------------------

    const getSuperAdminsListing = async (e, page, itemPerPage) => {
        if (!loader) {
            let post = {
                search: e ? e : keyword2 ? keyword2 : "",
                page: page ? page : 1,
                limit: itemPerPage ? itemPerPage : 10,
                sort: sortingValue ? sortingValue : ''
            };
            setLoader(true);
            let response = await adminController.getSuperAdminsListing(post);
            if (response && response.status) {
                setSuperAdminsList(response.data);
                setCount2(response.count);
                setSuperAdminsListLength(response.totalCount);
                setLoader(false);
            } else {
                setSuperAdminsList([]);
                setCount2(null);
                setSuperAdminsListLength(null);
                setLoader(false);
            }
        }
        else {
            setLoader(false);
        }
        setLoader(false);
    };


    useEffect(() => {
        if (count2) {
            handlePages2(count2);
        }
    }, [count2, itemsPerPage2]);


    const handlePages2 = (count) => {
        let array = []
        const data = parseInt(count);
        const items = parseInt(itemsPerPage2);
        const totalPage = Math.ceil(data / items);
        for (let i = 1; i <= totalPage; i++) {
            array.push(i)
        }
        setTotalPages2(array);
    };


    const handleBackClick2 = () => {                             //<------------PAZINATION
        if (page2 > 1) {
            let newPage = page2 - 1;
            setPage2(page2 - 1);
            getSuperAdminsListing("", newPage, itemsPerPage2);
        }
        else return;
    };


    const handleNextClick2 = () => {                              //<------------PAZINATION
        if (page2 >= 1 && page2 < totalPages2.length) {
            let newPage = page2 + 1;
            setPage2(page2 + 1);
            getSuperAdminsListing("", newPage, itemsPerPage2);
        }
        else return;
    };

    //<-------------------------------------------------------------------------------------------------

    const handleBreadcrumDataNaviagtion = (i) => {
        let user = store.getState().UserReducer.user;
        if (i === 0) {
            if (user && parseInt(user.user_type) === 2) {
                navigate("/dashboard");
            } else {
                navigate("/dashboard-superadmin");
            }
        } else if (i === 1) {
            navigate("/staff-list");
        } else return;
    };


    const applyFilters = async (filter) => {
        let filters = {
            department: filter && filter.department ? filter.department : [],
            role: filter && filter.role ? filter.role : [],
            hospitals: filter && filter.hospitals ? filter.hospitals : [],
            adminStatus: filter && filter.adminStatus ? filter.adminStatus : null,
        };
        if (filter && filter.hospitals && filter.hospitals.length > 0) {
            await filtersController.setStaffListFilters(filters);
            setShowFilterModal(false);
            setFiltersAppliedSign(true);
        } else {
            new Toaster().error("Please select at least one hospital");
        }
    };


    const handleClearAllFilters = async () => {
        let data = localStorage.getItem('SET_HOSPITAL');
        let item = JSON.parse(data);
        await filtersController.clearStaffListFilters(item);
    };


    const setReduxValue = (e) => {          //<------------For confirmation that we have checked the localstorage value's and RESET the REDUX- FILTERS's Values and after it the listing api will hit
        SetCheckStoredValueFromRedux(e);
    };


    const handleDrawer = async (e) => {
        await store.dispatch(setDrawer(false));
    };

    return {
        getModulePermissions,
        checkPermissions,
        breadcrumData,
        breadcrumItem,
        handleBreadcrumDataNaviagtion,
        getStafListing,
        stafList,
        setStafList,
        handleBackClick,
        handleNextClick,
        handleDeleteStaf,
        //<---------------
        page,
        setPage,
        totalPages,
        setTotalPages,
        itemsPerPage,
        //<---------------
        page2,
        setPage2,
        totalPages2,
        setTotalPages2,
        itemsPerPage2,
        handleBackClick2,
        handleNextClick2,
        //<---------------
        setItemsPerPage,
        searchInput,
        setSearchInput,
        showFilterModal,
        setShowFilterModal,
        navigate,
        loader,
        setLoader,
        handlePage,
        deletePopup,
        setDeletePopup,
        navigate,
        deletePopup,
        setDeletePopup,
        applyFilters,
        handleClearAllFilters,
        deleteItem,
        setDeleteItem,
        staffFilter,
        setStaffFilter,
        itemsPerPage,
        handleItemPerPage,
        stafListLength,
        setReduxValue,
        filtersAppliedSign,
        updateStafStatus,
        keyword,
        setSearch,
        handleDrawer,
        currentTab,
        setCurrentTab,
        tabs,
        setTabs,
        handleTabData,
        superAdminsListLength,
        superAdminsList,
        getSuperAdminsListing,
        setSearch2,
        keyword2,
        superAdminListFilters,
        setSuperAdminListFilters,
        sortingValue,
        setSortingValue,
        breadcrumData1,
        User,
        showMultiHosPopup,
        setShowMultiHosPopup
    };
};

export default StaffListFunctions;
