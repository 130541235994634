import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
//-------- HEADER COMPONENT IMPORT ---------//
import WithoutImageHeader from "../../../Helper/withoutImageHeader";
//------------ CONSTANTS IMPORT ------------//
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from 'react-select';
import 'react-tagsinput/react-tagsinput.css';
import AutocompleteInput from "../../../Helper/autocompleteInput";
import * as Constants from "../../../Helper/constants/constants";
import Loader from "../../../Helper/loader";
import AssignMedicineFunction from "./function";


const UpdateMedicine = () => {
    const {
        breadcrumData,
        breadcrumItem,
        isError,
        handleChange,
        handleSubmit,
        loader,
        getMedicineFrequencyList,
        medicineFrequenciesList,
        location,
        navigate,
        values,
        brandSuggestionsList,
        getBrandSuggestionsList,
        getMedicineSuggestionsList,
        medicineSuggestionsList,
        handleBreadcrumDataNaviagtion,
        getMedicineDetail
    } = AssignMedicineFunction()


    useEffect(() => {
        if (location && location.state && location.state.id) {
            let id = location.state.id
            getMedicineDetail(id);
            getMedicineFrequencyList();
            getBrandSuggestionsList();
            getMedicineSuggestionsList();
        }
        else {
            navigate(-1);
        }
    }, []);


    return (
        <div className="assign_medicine_section manage_tags">
            <WithoutImageHeader
                header={Constants.EDIT_MEDICINE}
                breadcrumData={breadcrumData}
                breadcrumItem={breadcrumItem}
                handleBreadcrumDataNaviagtion={(i) => handleBreadcrumDataNaviagtion(i)}
                handleBack={() => navigate(-1)}
            />
            <div className="assign_medicine_area">
                <Container>
                    {
                        loader ?
                            <Loader
                                loader={loader}
                            />
                            :
                            <Row>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="medicine_main_area">
                                        <div className="form_area">
                                            <Form>
                                                <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Brand Name<span>*</span></Form.Label>
                                                        <AutocompleteInput
                                                            placeholder={'Select Brand Name Here'}
                                                            data={brandSuggestionsList ? brandSuggestionsList : []}
                                                            isError={!isError.brandName.isValid
                                                                ? true
                                                                : false}
                                                            value={values && values.brandName}
                                                            onChange={(e) => handleChange("brandName", e)}
                                                        />
                                                        {!isError.brandName.isValid ? (
                                                            <p className="text-danger mt-2">
                                                                {isError.brandName.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Medicine Name<span>*</span></Form.Label>
                                                        <AutocompleteInput
                                                            placeholder={'Select Medicine Name Here'}
                                                            data={medicineSuggestionsList?medicineSuggestionsList:[]}
                                                            isError={!isError.medicineName.isValid
                                                                ? true
                                                                : false}
                                                            value={values && values.medicineName}
                                                            onChange={(e) => handleChange("medicineName", e)}
                                                        />
                                                        {!isError.medicineName.isValid ? (
                                                            <p className="text-danger mt-2">
                                                                {isError.medicineName.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Salt<span>*</span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder={'Enter salt here'}
                                                            value={values && values.salt}
                                                            className={!isError.salt.isValid
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(e) => handleChange("salt", e.target.value)}
                                                        />
                                                        {!isError.salt.isValid ? (
                                                            <p className="text-danger mt-2">
                                                                {isError.salt.message}
                                                            </p>
                                                        ) : null}
                                                    </Form.Group>
                                                </Col>
                                                <Row>
                                                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Dosage<span>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={'Enter Dosage here'}
                                                                value={values && values.dosage}
                                                                className={!isError.dosage.isValid
                                                                    ? "error_border"
                                                                    : ""}
                                                                onChange={(e) => handleChange("dosage", e.target.value)}
                                                            />
                                                            {!isError.dosage.isValid ? (
                                                                <p className="text-danger mt-2">
                                                                    {isError.dosage.message}
                                                                </p>
                                                            ) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                                                        <Form.Group className="form-group">
                                                            <Form.Label>Route<span>*</span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={'Enter route here'}
                                                                value={values && values.route}
                                                                className={!isError.route.isValid
                                                                    ? "error_border"
                                                                    : ""}
                                                                onChange={(e) => handleChange("route", e.target.value)}
                                                            />
                                                            {!isError.route.isValid ? (
                                                                <p className="text-danger mt-2">
                                                                    {isError.route.message}
                                                                </p>
                                                            ) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <div className="tag_area">
                                                    <h6>Frequency<span>*</span></h6>
                                                    <div className="dropdown_area">
                                                        <Select
                                                            isSearchable
                                                            options={medicineFrequenciesList}
                                                            value={values && values.frequency}
                                                            className={!isError.frequency.isValid
                                                                ? "error_border"
                                                                : ""}
                                                            onChange={(item) => handleChange("frequency", item)}
                                                        />
                                                        {!isError.frequency.isValid ? (
                                                            <p className="text-danger mt-2">
                                                                {isError.frequency.message}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="btn_area">
                                            <Button
                                                className="cancelBtn"
                                                type="button"
                                                onClick={() => navigate(-1, { state: { id: location.state.patientId, name: 'fromAssignMedicine' } })}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="submitBtn"
                                                type="submit"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                    }
                </Container>
            </div>
        </div>
    );
};

export default UpdateMedicine;
