//------------- REACT IMPORT ---------------//
import React, { useRef, useState } from "react";
//---------- FONTAWESOME IMPORT ------------//
import { faCalendar, faAlternateCalendar, faCheckCircle, faCircle, faCircleCheck, faCalendarMinus, faEdit, faTrashCan, faCommentDots, faPlus, faCheck, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//-------- BOOTSTRAP ITEMS IMPORT ----------//
import { Accordion, Button, Card, Col, Container, Dropdown, Form, Image, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import PaginationComponent from "../../CommanComponents/Pagination";
import { CalendarBlack } from "../../../../assets/images";
import { dateFormat, dateFormat2, dateFormat3, isEmptyArray } from "../../../Helper/General";
import DeleteModal from "../../Modals/DeleteModal";
import NoDataComponent from "../../CommanComponents/NoDataComponent";
import MedicineCommentModel from "../../Modals/AddCommentMedicine";
import moment from "moment";

const MedicineUpdate = (props) => {
    const {
        medicineList,
        setMedicineList,
        completeMedicine,
        setCompleteMedicine,
        page,
        handlePage,
        handleMedicineBackClick,
        totalPages,
        handleMedicineNextClick,
        handleDeleteMedicine,
        patientCaseDetail,
        navigate,
        setDeletMedicineDetail,
        deletConfirmationModal,
        setDeletConfirmationModal,
        handleCompleteMedicine,
        completMedicalConfirmationModal,
        setCompletMedicineConfimationModal,
        setCompleteMedicineDetail,
        setMedicineComment,
        medicineComment,
        medicineDataForCommentApi,
        setMedicineDataForCommentApi,
        handleAddCommentInMedicineTab,
        dateParam,
        setDateParam,
        patientData,
        checkMedicinePermissions,
        User,
        handleCompleteAllMedicine,
        handleAllMedicine,
        selectAllMedicine,
        setSelectAllMedicine,
        allmedicineCompletePopup,
        setAllmedicineCompletePopup,
        checkAllSelecMedicine
    } = props;

    const inputCal = useRef(null);

    const handleClick = () => {
        if (inputCal.current) {          //---------------date picker----
            inputCal.current.showPicker();
        }
    };
    // const [selectAllMedicine, setSelectAllMedicine] = useState();

    // const handleAllMedicine = (items) => {
    //     const newArray = [...completeMedicine];
    //     if (items) {
    //         medicineList.forEach((item) => {
    //             if (!item.checked && parseInt(item.medicine_status) === 0) {
    //                 newArray.push({ id: item.medicine_id, frequency_id: item.frequency_id });
    //                 item.checked = true;
    //             }
    //         });
    //         setSelectAllMedicine(true)
    //         setCompleteMedicine(newArray);
    //     } else {
    //         medicineList.forEach((item) => {
    //             item.checked = false;
    //         });
    //         setSelectAllMedicine(false)
    //         setCompleteMedicine([]);
    //     }
    // };

    return (
        <>
            <div className="medicine_update_screen">
                <Row>
                    <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}
                    >
                        <div className="medicine_main_area">
                            <div className="date_main">
                                <div className="left_area">
                                    <p>Date : {dateFormat(dateParam)}</p>
                                    <div className="check_select">
                                        <Form.Check
                                            type="checkbox"
                                            checked={selectAllMedicine}
                                            label="Select All"
                                            onChange={(e) => handleAllMedicine(e.target.checked)}
                                        />
                                    </div>
                                    {!isEmptyArray(completeMedicine) ? <div className="dropdown_select">
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="none"
                                                id="dropdown-basic"
                                            >
                                                <FontAwesomeIcon
                                                    icon={
                                                        faEllipsisVertical
                                                    }
                                                    className="ellipsis_icon"
                                                />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => setAllmedicineCompletePopup(true)}>Mark as completed</Dropdown.Item>
                                                {/* <Dropdown.Item>Delete All</Dropdown.Item> */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div> : null}
                                </div>
                                <div className="right_area" style={{ position: 'relative' }}>
                                    <Form.Control
                                        style={{
                                            position: 'absolute',
                                            zIndex: 1,
                                            height: '1px',
                                            width: '295px',
                                            right: '0',
                                            left: 'none',
                                            top: '15px',
                                            visibility: 'hidden'
                                        }}
                                        type="date"
                                        ref={inputCal}
                                        placeholder="date"
                                        value={dateParam}
                                        onChange={(e) => {
                                            setDateParam(e.target.value)
                                        }}

                                    />
                                    <div className="img_area">
                                        <Image src={CalendarBlack} alt="..."
                                            onClick={() => handleClick()}
                                        />

                                    </div>
                                </div>
                            </div>
                            {medicineList &&
                                medicineList.length > 0 ? (
                                <Row>
                                    {medicineList.map((item, index) => {
                                        return (
                                            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}
                                            >
                                                <div className="accordion_area" key={index}>
                                                    <Accordion defaultActiveKey="0">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>
                                                                {item && item.frequency_title}</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="inner_body_area">
                                                                    <div className="top_area">
                                                                        {parseInt(item.medicine_status) === 0 ? <div className="check_box">
                                                                            <Form.Check
                                                                                type="checkbox"
                                                                                checked={item.checked}
                                                                                onClick={() => {
                                                                                    let array = completeMedicine ? [...completeMedicine] : [];
                                                                                    if (item.checked) {
                                                                                        var itemIndex = array.findIndex(e => e.id === item.medicine_id)
                                                                                        if (itemIndex !== -1) {
                                                                                            array.splice(itemIndex, 1);
                                                                                            setSelectAllMedicine(false)
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        array.push({ id: item.medicine_id, frequency_id: item.frequency_id });
                                                                                    }
                                                                                    item.checked = !item.checked
                                                                                    setCompleteMedicine(array);
                                                                                    //checkAllSelecMedicine
                                                                                }}
                                                                            />
                                                                        </div> : null}
                                                                        <div className="top_left_area">
                                                                            <div className="text_area">
                                                                                <p style={{ textTransform: 'capitalize' }}>{item && item.medicine_name}</p>
                                                                                <div className="label"> {item && item.dosage}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="top_right_area">
                                                                            <div className="main_area">
                                                                                {
                                                                                    patientData && patientData.patient_status !== 'discharge' ?
                                                                                        <div className="icon_area">
                                                                                            {

                                                                                                User && parseInt(User.user_type) === 1 && parseInt(item.medicine_status) === 0 ?
                                                                                                    <FontAwesomeIcon
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        className="icon_style" icon={faEdit}
                                                                                                        onClick={() => navigate('/edit-medicine', { state: { id: item.id, patientId: item.patient_id, fromNavigation: true } })}
                                                                                                    />
                                                                                                    :
                                                                                                    checkMedicinePermissions
                                                                                                        && checkMedicinePermissions.permissions
                                                                                                        && checkMedicinePermissions.permissions.length > 2
                                                                                                        && parseInt(checkMedicinePermissions.permissions[2].value) === 1 &&
                                                                                                        parseInt(item.medicine_status) === 0 ?
                                                                                                        <FontAwesomeIcon
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className="icon_style" icon={faEdit}
                                                                                                            onClick={() => navigate('/edit-medicine', { state: { id: item.id, patientId: item.patient_id, fromNavigation: true } })}
                                                                                                        />
                                                                                                        :
                                                                                                        null
                                                                                            }
                                                                                            {
                                                                                                User && parseInt(User.user_type) === 1 && parseInt(item.medicine_status) === 0 ?
                                                                                                    <FontAwesomeIcon
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        className="icon_style"
                                                                                                        icon={faTrashCan}
                                                                                                        onClick={() => (setDeletMedicineDetail(item), setDeletConfirmationModal(true))}
                                                                                                    />
                                                                                                    :
                                                                                                    checkMedicinePermissions
                                                                                                        && checkMedicinePermissions.permissions
                                                                                                        && checkMedicinePermissions.permissions.length > 3
                                                                                                        && parseInt(checkMedicinePermissions.permissions[3].value) === 1 && parseInt(item.medicine_status) === 0 ?
                                                                                                        <FontAwesomeIcon
                                                                                                            style={{ cursor: "pointer" }}
                                                                                                            className="icon_style"
                                                                                                            icon={faTrashCan}
                                                                                                            onClick={() => (setDeletMedicineDetail(item), setDeletConfirmationModal(true))}
                                                                                                        />
                                                                                                        :
                                                                                                        null
                                                                                            }
                                                                                            {<FontAwesomeIcon
                                                                                                style={{ cursor: "pointer" }}
                                                                                                className="icon_style"
                                                                                                icon={faCommentDots}
                                                                                                onClick={() => (setMedicineComment(true), setMedicineDataForCommentApi(item))}
                                                                                            />}
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <div>
                                                                                    {
                                                                                        parseInt(item.medicine_status) === 0 ?
                                                                                            <a className="pending">Pending</a>
                                                                                            :
                                                                                            parseInt(item.medicine_status) === 1 ?
                                                                                                <a className="complete">Completed</a>
                                                                                                :
                                                                                                null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mid_content_area">
                                                                        <div className="left_con_area">
                                                                            <ul
                                                                                style={{ textTransform: 'capitalize' }}
                                                                            >
                                                                                {
                                                                                    item && item.brand_name ?
                                                                                        <li>Brand Name : {item && item.brand_name}</li>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    item && item.route ?
                                                                                        <li>Route : {item && item.route}</li>
                                                                                        :
                                                                                        null

                                                                                }
                                                                                {
                                                                                    item && item.salt ?
                                                                                        <li>Salt: {item && item.salt}</li>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {/* <li>Last Given By : <span>Julia ( Nurse )</span></li> */}
                                                                                {
                                                                                    parseInt(item.completed_on) === 1 ?
                                                                                        <li>Date & Time : {dateFormat3(item.completed_on)}</li>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    item && item.comment ?
                                                                                        <li>Comment : {item && item.comment}</li>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    item && item.comment_admin_name ?
                                                                                        <li>Comment by : {item && item.comment_admin_name}</li>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    item && item.completeby_admin_name ?
                                                                                        <li>Completed by : {item && item.completeby_admin_name}</li>
                                                                                        :
                                                                                        null
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        {
                                                                            parseInt(item.medicine_status) === 0 ?
                                                                                <div className="right_con_area" onClick={() => (setCompletMedicineConfimationModal(true), setCompleteMedicineDetail(item))} style={{ cursor: 'pointer' }}>
                                                                                    <a>Complete</a>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                    <div className="bottom_area">
                                                                        <div className="left_area">
                                                                            {
                                                                                item && item.admin_name ?
                                                                                    <p style={{ cursor: 'pointer' }}
                                                                                        onClick={() => navigate(`/staff-detail/${item.created_by}`, { state: { name: item.admin_name, fromNavigation: true } })}
                                                                                    >Assigned by : <span>{item && item.admin_name} {item.admin_role ? `(${item && item.admin_role})` : ''}</span></p>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                        <div className="right_area">
                                                                            <p>Assigned on : {dateFormat3(item.created)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            ) :
                                <NoDataComponent
                                    message="No Medicine Found"
                                />}
                        </div>
                    </Col>
                </Row>
                {
                    User && parseInt(User.user_type) === 1
                        && patientData && patientData.patient_status !== 'discharge' ?
                        <div className="assign_task">
                            <a >
                                <div className="circle_area"
                                    onClick={() => navigate('/assign-medicine', { state: { patientCaseId: patientCaseDetail && patientCaseDetail.id, patientId: patientCaseDetail && patientCaseDetail.patient_id, fromNavigation: true } })}>
                                    <div className="add_icon_area" >
                                        <FontAwesomeIcon icon={faPlus} />
                                        <p style={{ cursor: 'pointer' }}>Assign Medicine</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        :
                        checkMedicinePermissions
                            && checkMedicinePermissions.permissions
                            && checkMedicinePermissions.permissions.length > 1
                            && parseInt(checkMedicinePermissions.permissions[1].value) === 1
                            && patientData && patientData.patient_status !== 'discharge' ?
                            <div className="assign_task">
                                <a >
                                    <div className="circle_area"
                                        onClick={() => navigate('/assign-medicine', { replace: true, state: { patientCaseId: patientCaseDetail && patientCaseDetail.id, patientId: patientCaseDetail && patientCaseDetail.patient_id, fromNavigation: true } })}>
                                        <div className="add_icon_area" >
                                            <FontAwesomeIcon icon={faPlus} />
                                            <p style={{ cursor: 'pointer' }}>Assign Medicine</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            :
                            null
                }
            </div>
            {medicineList && medicineList.length > 0 ? (
                <PaginationComponent
                    page={page}
                    setPage={handlePage}
                    totalPages={totalPages}
                    handleBackClick={handleMedicineBackClick}
                    handleNextClick={handleMedicineNextClick}
                />
            ) : null}
            {
                deletConfirmationModal ?
                    <DeleteModal
                        show={deletConfirmationModal}
                        handleSubmit={() => handleDeleteMedicine()}
                        close={() => setDeletConfirmationModal(false)}
                        message={'Do you want to delete this Medicine ?'}
                        icon={null}
                    />
                    :
                    null
            }
            {
                completMedicalConfirmationModal ?
                    <DeleteModal
                        show={completMedicalConfirmationModal}
                        handleSubmit={() => handleCompleteMedicine()}
                        close={() => setCompletMedicineConfimationModal(false)}
                        message={'Do you want to mark this medicine as "Completed" ?'}
                        icon={faCheck}
                        taskCompeled={'true'}
                    />
                    :
                    null
            }
            {
                allmedicineCompletePopup ?
                    <DeleteModal
                        show={allmedicineCompletePopup}
                        handleSubmit={() => handleCompleteAllMedicine()}
                        close={() => setAllmedicineCompletePopup(false)}
                        message={'Do you want to mark all medicines as completed?'}
                        icon={faCheck}
                        taskCompeled={'true'}
                    />
                    :
                    null
            }
            {medicineComment ?
                <MedicineCommentModel
                    show={medicineComment}
                    handleAddComment={handleAddCommentInMedicineTab}
                    close={() => setMedicineComment(false)}
                    medicineDataForCommentApi={medicineDataForCommentApi}
                /> : null}
        </>
    )
}

export default MedicineUpdate;

